import { useState, useEffect } from "react";
import {
  getOrders,
  getOrders_history,
  getOrdersByOrders_uuid,
  getOrderV2,
} from "../libs/customerAPI/getOrders";
import { useAuthStore } from "./useAuthStore";
import { useGuestLogin } from "./useGuestLogin";

export const useOrders = (page = 1) => {
  const { user } = useAuthStore();
  const { isGuest } = useGuestLogin();
  const [orders, setOrders] = useState([]);
  const [ordersHistory, setOrdersHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   console.log("user", user);
  // }, [user]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        if (!user) {
          setIsLoading(false);
          return;
        }
        const data = {
          customer_uuid: user?.uuid,
          active: true,
          page: 1,
          size: 100,
        };
        const response = await getOrderV2(data);
        setOrders(response.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    const fetchGuestOrders = async () => {
      const guestOrderList = localStorage.getItem("guest_order");
      if (guestOrderList) {
        const guestOrders = JSON.parse(guestOrderList);
        const orders = await Promise.all(
          guestOrders.map(async (orderUuid) => {
            const response = await getOrdersByOrders_uuid(orderUuid);
            return response.data;
          })
        );
        setOrders(orders);
      }
    };

    if (!isGuest) {
      fetchOrders();
    } else {
      fetchGuestOrders();
    }
  }, [isGuest, user.uuid]);

  useEffect(() => {
    const fetchOrdersHistory = async () => {
      try {
        if (!user) {
          setIsLoading(false);
          return;
        }
        const data = {
          customer_uuid: user?.uuid,
          active: false,
          page: page,
          size: 5,
        };
        const response = await getOrderV2(data);
        setOrdersHistory(response.data);
      } catch (error) {
        console.error("Error fetching orders history:", error);
      }
    };
    fetchOrdersHistory();
  }, [user?.uuid, page]);

  useEffect(() => {
    if (
      orders?.length > 0 ||
      orders.orders?.length > 0 ||
      ordersHistory.orders?.length > 0
    ) {
      setIsLoading(false);
    }
  }, [orders, ordersHistory]);

  return { orders, ordersHistory, isLoading };
};
