import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import {
  getBMerchantById,
  getServiceBMerchant,
} from "../../libs/merchantAPI/merchantAPI";
import LoadingScreen from "../../components/LoadingScreen";
import { getBill } from "../../libs/orderAPI/orderAPI";
import Billitem from "./Billitem";
import BillTableItem from "./BillTableItem";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

function BillPage() {
  const { t } = useTranslation();
  const { merchant_id, transaction_uuid, bmerchant_id } = useParams();
  const [isFetch, setIsFetch] = useState(false);
  const [billData, setBillData] = useState([]);
  const [service, setService] = useState({});
  const [bmerchant, setBmerchant] = useState({});
  const [isTable, setIsTable] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/order/bill/${merchant_id}/${bmerchant_id}/${transaction_uuid}`,
      title: "BillPage",
    });
  }, []);
  useEffect(() => {
    getBill(transaction_uuid)
      .then((res) => {
        setBillData(res.data);
        if (res.data.orders.length > 1) {
          setIsTable(true);
        }
        return getBMerchantById(bmerchant_id);
      })
      .then((res) => {
        const data = res.data;
        setBmerchant(data);
        return getServiceBMerchant(data.uuid);
      })
      .then((res) => {
        setService(res.data);
        setIsFetch(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsFetch(true); // Ensure setIsFetch is called even in case of error
      });
  }, [bmerchant_id, transaction_uuid]);

  if (!isFetch) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Helmet>
        <title>
          {t("pompkins")} - {t("title.billpage")}
        </title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link rel="canonical" href={`${window.location.href}`} />
        <meta property="og:url" content="https://pompkins.com/order/bill" />
      </Helmet>
      <Box
        width={"100%"}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 4,
        }}
      >
        {!isTable ? (
          <Billitem
            billData={billData}
            serviceData={service}
            bmerchantData={bmerchant}
          />
        ) : (
          <BillTableItem
            billData={billData}
            serviceData={service}
            bmerchantData={bmerchant}
          />
        )}
      </Box>
    </>
  );
}

export default BillPage;
