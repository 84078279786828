import React from "react";
import {
  Box,
  Tabs,
  Tab,
  IconButton,
  Modal,
  TextField,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useMobile } from "../stores/useMobile";
import MenuSearch from "./MenuSearch";
import { get } from "https";
import MenuSearchBarComponent from "./MenuSearchBarComponent";
import { useTranslation } from "react-i18next";
MenuShopCategoryTab.defaultProps = {
  isQr: false,
  openNoPayment: false,
  openNoService: false,
};
function MenuShopCategoryTab({
  scrollToCategory,
  isQr,
  displayMenu,
  cartList,
  setCartList,
  handleQuantityChange,
  openNoPayment,
  openNoService,
  openCloseModal,
  searchValue,
  setSearchValue,
}) {
  const { isMobile } = useMobile();
  const [value, setValue] = React.useState(0);
  const [openSearch, setOpenSearch] = React.useState(false);
  const { t } = useTranslation();
  const handleTabClick = (event, index) => {
    scrollToCategory(index);
    setValue(index);
  };

  const handleOpen = () => setOpenSearch(true);
  const handleClose = () => setOpenSearch(false);

  const getTopValue = (isQr, isMobile) => {
    if (isQr && isMobile) return "50px";
    if (isQr) return "50px";
    if (isMobile) return "60px";
    return ["70px", "90px", "50px"];
  };

  const getPtValue = (isQr, isMobile) => {
    if (isQr) return "11px";
    if (isMobile) return "28px";
    return ["11px", 2, 3];
  };

  return (
    <Box
      sx={{
        pt: getPtValue(isQr, isMobile),
        boxShadow: "0 22px 20px -22px lightgray",
        position: "sticky",
        "@media (min-width: 300px) and (max-width: 600px)": {
          top: getTopValue(isQr, isMobile),
        },
        top: getTopValue(isQr, isMobile),
        zIndex: 1000,
        backgroundColor: "white",
        borderBottomLeftRadius: "15px",
        borderBottomRightRadius: "15px",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderTop: "1px solid lightgray",
          width: "100%",
        }}
      >
        <Box sx={{ ml: 2 }}>
          {isMobile ? (
            <IconButton onClick={handleOpen} disableRipple>
              <SearchIcon />
            </IconButton>
          ) : (
            <MenuSearchBarComponent
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              placeholder={t("searchInMenu")}
              sx={{}}
            />
          )}
        </Box>

        <Tabs
          value={value}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleTabClick}
          sx={{
            backgroundColor: "white",
            "& button:hover": { backgroundColor: "#FFEED9", color: "#FF961B" },
            "& button:focus": { backgroundColor: "#FFFFFF", color: "#FF961B" },
            "& .Mui-selected": {
              backgroundColor: "#FFFFFF",
              color: "#FF961B !important",
            },
            "& .Mui-active": {
              backgroundColor: "#FFFFFF",
              color: "#FF961B",
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "#FF961B",
            },
          }}
        >
          {displayMenu &&
            displayMenu
              .filter((item) => item.menu.length !== 0)
              .sort((a, b) => a.category.priority - b.category.priority)
              .map((item, index) => (
                <Tab
                  key={index}
                  sx={{
                    typography: "subtitle1",
                  }}
                  label={`${item.category.name} (${item.category.used_in})`}
                  onClick={(event) => handleTabClick(event, index)}
                />
              ))}
        </Tabs>
      </Box>

      <Modal open={openSearch} onClose={handleClose}>
        <MenuSearch
          isQr={isQr}
          handleClose={handleClose}
          displayMenu={displayMenu}
          cartList={cartList}
          setCartList={setCartList}
          handleQuantityChange={handleQuantityChange}
          openNoPayment={openNoPayment}
          openNoService={openNoService}
          openCloseModal={openCloseModal}
        />
      </Modal>
    </Box>
  );
}

export default MenuShopCategoryTab;
