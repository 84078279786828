import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  RadioGroup,
  Radio,
  Checkbox,
  Divider,
  IconButton,
  Avatar,
  useTheme,
  useMediaQuery,
  SwipeableDrawer,
} from "@mui/material";
import { Close, AddCircle } from "@mui/icons-material";
import DefaultProfile from "../assets/images/pompkins_food_logo.png";
import { useTranslation } from "react-i18next";

function MenuEditShopCardModal({
  items,
  open,
  setOpen,
  handleCloseModal,
  cartList,
  setCartList,
  isAddDisabled,
  setIsAddDisabled,
  count,
}) {
  const { t } = useTranslation();
  const [cartNote, setCartNote] = useState("");
  const [optionList, setOptionList] = useState([]);
  const [cartQuantity, setCartQuantity] = useState(1);
  const [priceOffsetList, setPriceOffsetList] = useState([]);
  const [priceOffset, setPriceOffset] = useState(0);
  const [isSelectedOptions, setIsSelectedOptions] = useState(false);
  const [isAddDisabledModal, setIsAddDisabledModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const DialogComponent = isMobile ? SwipeableDrawer : Dialog;

  useEffect(() => {
    const existingItem = cartList.find((item) => item.menu.id === items.id);
    if (existingItem) {
      setCartNote(existingItem.note || "");
      setOptionList(existingItem.option || []);
      setCartQuantity(existingItem.quantity || 1);
      setPriceOffset(existingItem.price - items.price);
    }
  }, [items, cartList]);

  useEffect(() => {
    setIsSelectedOptions(checkRequiredOptionsSelected());
  }, [optionList, items.options]);

  const resetForm = () => {
    const existingItem = cartList.find((item) => item.menu.id === items.id);
    if (existingItem) {
      setCartNote(existingItem.note || "");
      setOptionList(existingItem.option || []);
      setCartQuantity(existingItem.quantity || 1);
      setPriceOffset(existingItem.price - items.price);
    }
  };

  const handleClose = () => {
    resetForm();
    setOpen(false);
  };

  const handleQuantityChange = (delta) => {
    const newQuantity = cartQuantity + delta;
    if (newQuantity > 0) {
      setCartQuantity(newQuantity);
    }
  };

  const handleChangeOptionRadio = (optionName, subOptionName, price) => {
    const newOptionList = optionList.filter(
      (option) => !option.startsWith(optionName + ":")
    );
    newOptionList.push(`${optionName}: ${subOptionName}`);
    setOptionList(newOptionList);
    updatePriceOffset(newOptionList);
  };

  const handleChangeOptionCheckbox = (
    optionName,
    subOptionName,
    selected,
    price
  ) => {
    let newOptionList = [...optionList];
    const optionString = `${optionName}: ${subOptionName}`;
    if (selected) {
      newOptionList.push(optionString);
    } else {
      newOptionList = newOptionList.filter((option) => option !== optionString);
    }
    setOptionList(newOptionList);
    updatePriceOffset(newOptionList);
  };

  const updatePriceOffset = (newOptionList) => {
    const newPriceOffsetList = newOptionList.map((option) => {
      const [optionName, subOptionName] = option.split(": ");
      const optionData = items.options.find((opt) => opt.name === optionName);
      const subOptionData = optionData.sub_option.find(
        (subOpt) => subOpt.name === subOptionName
      );
      return { optionName, subOptionName, price: subOptionData.price };
    });

    setPriceOffsetList(newPriceOffsetList);

    const totalOffset = newPriceOffsetList.reduce(
      (sum, item) => sum + item.price,
      0
    );
    setPriceOffset(totalOffset);
  };
  const checkRequiredOptionsSelected = () => {
    if (!items.options) return true;
    return items.options.every((option) => {
      if (option.required) {
        return optionList.some((selectedOption) =>
          selectedOption.startsWith(option.name + ":")
        );
      }
      return true;
    });
  };

  const handleUpdateCart = () => {
    if (items.limited && count + cartQuantity > items.existed) {
      setIsAddDisabledModal(true);
      return;
    }

    const updatedItem = {
      menu: items,
      quantity: cartQuantity,
      option: optionList,
      note: cartNote,
      price: items.price + priceOffset,
    };

    const existingItemIndex = cartList.findIndex(
      (cartItem) => cartItem.menu.id === items.id
    );

    let updatedCartList;
    if (existingItemIndex !== -1) {
      // Update existing item
      updatedCartList = cartList.map((cartItem, index) =>
        index === existingItemIndex ? updatedItem : cartItem
      );
    } else {
      // Add new item
      updatedCartList = [...cartList, updatedItem];
    }

    setCartList(updatedCartList);
    handleClose();
  };

  const dialogProps = isMobile
    ? {
        anchor: "bottom",
        open: open,
        onClose: handleClose,
        onOpen: () => setOpen(true),
        disableSwipeToOpen: false,
        PaperProps: {
          style: {
            height: "100%",
          },
        },
      }
    : {
        open: open,
        onClose: handleCloseModal,
        fullWidth: true,
        sx: {
          maxHeight: "100vh",
          "& .MuiDialog-paper": {
            borderRadius: "20px",
          },
        },
      };

  return (
    <DialogComponent {...dialogProps}>
      {/* Close button */}
      <IconButton
        color="inherit"
        onClick={handleClose}
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          borderRadius: "50%",
          p: 0.5,
          backgroundColor: "background.paper",
          transition: "transform 0.3s ease-in-out",
          "&:hover": {
            transform: "scale(1.1)",
            backgroundColor: "background.paper",
          },
        }}
      >
        <Close sx={{ color: "#FF961B" }} />
      </IconButton>

      {/* Item image */}
      <Box
        alt={items.name}
        component="img"
        src={items.image || DefaultProfile}
        sx={{
          maxHeight: "30vh",
          objectFit: items.image ? "cover" : "contain",
        }}
      />

      <DialogTitle sx={{ typography: "h5" }}>{items.name}</DialogTitle>
      <DialogContent>
        {/* Item description */}
        {items.description && (
          <>
            <Typography sx={{ typography: "body1" }}>
              {items.description}
            </Typography>
            <Divider sx={{ my: 2 }} />
          </>
        )}

        {/* Options */}
        {items.options &&
          items.options.map((option) => (
            <Box
              key={option.id}
              sx={{ bgcolor: "#fff5e9", p: 2, borderRadius: "10px", mb: 2 }}
            >
              <Typography sx={{ typography: "h6" }}>{option.name}</Typography>
              {option.required ? (
                <RadioGroup
                  onChange={(event) => {
                    const [subOptionName, price] =
                      event.target.value.split("|||");
                    handleChangeOptionRadio(
                      option.name,
                      subOptionName,
                      parseFloat(price)
                    );
                  }}
                >
                  {option.sub_option.map((subOption) => (
                    <label key={subOption.id} htmlFor={`radio-${subOption.id}`}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          cursor: "pointer",
                          ":hover": {
                            bgcolor: "#ffebcf",
                            borderRadius: "10px",
                          },
                        }}
                      >
                        <Radio
                          id={`radio-${subOption.id}`}
                          required={true}
                          value={`${subOption.name}|||${subOption.price}`}
                          checked={optionList.includes(
                            `${option.name}: ${subOption.name}`
                          )}
                          sx={{
                            color: "#FF961B",
                            "&.Mui-checked": {
                              color: "#FF961B",
                            },
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flex: 1,
                          }}
                        >
                          <Typography sx={{ typography: "body1", flex: 1 }}>
                            {subOption.name}
                          </Typography>
                          <Typography sx={{ typography: "body1", mr: "12px" }}>
                            {subOption.price}
                          </Typography>
                        </Box>
                      </Box>
                    </label>
                  ))}
                </RadioGroup>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                  }}
                >
                  {option.sub_option.map((subOption) => (
                    <label
                      key={subOption.id}
                      htmlFor={`checkbox-${subOption.id}`}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          cursor: "pointer",
                          ":hover": {
                            bgcolor: "#ffebcf",
                            borderRadius: "10px",
                          },
                        }}
                      >
                        <Checkbox
                          id={`checkbox-${subOption.id}`}
                          value={subOption.id}
                          checked={optionList.includes(
                            `${option.name}: ${subOption.name}`
                          )}
                          onChange={(event) =>
                            handleChangeOptionCheckbox(
                              option.name,
                              subOption.name,
                              event.target.checked,
                              subOption.price
                            )
                          }
                          sx={{
                            color: "#FF961B",
                            "&.Mui-checked": {
                              color: "#FF961B",
                            },
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flex: 1,
                          }}
                        >
                          <Typography sx={{ typography: "body1", flex: 1 }}>
                            {subOption.name}
                          </Typography>
                          <Typography sx={{ typography: "body1", mr: "12px" }}>
                            {subOption.price}
                          </Typography>
                        </Box>
                      </Box>
                    </label>
                  ))}
                </Box>
              )}
            </Box>
          ))}

        {items.options && <Divider sx={{ my: 2 }} />}

        {/* Note */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            mb: 2,
          }}
        >
          <Typography fontSize={"18px"} fontWeight={700} mb={"4px"}>
            หมายเหตุ
          </Typography>
          <Typography mb={1} fontSize={"14px"}>
            คุณสามารถใส่ข้อมูลเพิ่มเติมได้ที่นี่
          </Typography>
          <TextField
            multiline
            rows={2}
            placeholder={t("merchantPage.note")}
            value={cartNote}
            onChange={(e) => setCartNote(e.target.value)}
            variant="outlined"
            InputProps={{ sx: { borderRadius: "10px" } }}
            sx={{
              flex: 1,
              "& .MuiInput-underline:after": {
                borderBottomColor: "#FF961B",
              },
            }}
          />
        </Box>

        {/* Total price */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ typography: "h6", mr: 2, fontSize: "1.5rem" }}>
            ราคารวม
          </Typography>
          <Typography sx={{ typography: "h6", fontSize: "1.5rem" }}>
            {((items.price + priceOffset) * cartQuantity)
              .toFixed(2)
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
            {t("merchantPage.baht")}
          </Typography>
        </Box>
      </DialogContent>

      {/* Actions */}
      <DialogActions sx={{ px: "24px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
            my: 4,
          }}
        >
          <Avatar
            onClick={() => handleQuantityChange(-1)}
            sx={{
              backgroundColor: "#F1F1F1",
              fontSize: "30px",
              width: "25px",
              height: "25px",
              color: "black",
              cursor: "pointer",
            }}
          >
            -
          </Avatar>
          <Typography>{cartQuantity}</Typography>
          <AddCircle
            onClick={() => handleQuantityChange(1)}
            sx={{
              color:
                isAddDisabled || isAddDisabledModal ? "#E0E0E0" : "#FF961B",
              fontSize: "30px",
              cursor:
                isAddDisabled || isAddDisabledModal ? "not-allowed" : "pointer",
            }}
          />
        </Box>
        <Button
          variant="contained"
          fullWidth
          disabled={!isSelectedOptions}
          sx={{
            backgroundColor: !isSelectedOptions ? "#B0B0B0" : "#FF961B",
            borderRadius: "30px",
            padding: "6px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
          }}
          onClick={handleUpdateCart}
        >
          <Typography sx={{ typography: "body1", color: "#FFFFFF" }}>
            {t("merchantPage.updateCart")}
          </Typography>
        </Button>
      </DialogActions>
    </DialogComponent>
  );
}

export default MenuEditShopCardModal;
