import React, { useEffect, useState } from "react";
import { Box, Typography, Card, Avatar, Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DefaultProfile from "../../utils/defaultImage";
import ModalImage from "../ModalImage";
import { DeleteOutline } from "@mui/icons-material";
import MenuEditShopCardModal from "../MenuEditShopCardModal";
import ModalConfirmDelete from "../../pages/Cart/ModalConfirmDelete";

const CartItem = ({
  item,
  index,
  handleQuantityChange,
  cartList,
  setCartList,
}) => {
  const [imageModal, setImageModal] = useState(false);
  const [isAddDisabled, setIsAddDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);

  const calculateTotalPrice = (item) => {
    const total = parseInt(item.quantity, 10) * parseInt(item.price, 10);
    return parseFloat(total.toFixed(2)); // Returns number
  };

  const handleAddButton = () => {
    if (isAddDisabled) {
      return;
    }
    handleQuantityChange(index, 1);
  };

  const handleConfirmDelete = () => {
    handleQuantityChange(index, -item.quantity);
    setIsConfirmDeleteOpen(false);
  };

  useEffect(() => {
    if (item.menu.limited) {
      setIsAddDisabled(item.quantity >= item.menu.existed);
    }
  }, [item, item.quantity, item.menu.limited, item.menu.existed]);

  return (
    <>
      <Card
        key={item.menu.id}
        sx={{ mt: 2, boxShadow: 0, borderRadius: 2, overflow: "hidden" }}
      >
        <Box sx={{ p: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                border: "1px solid #D9D9D9",
                borderRadius: "6px",
                width: "25px",
                height: "25px",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography sx={{}}>{item.quantity}</Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                ml: 2,
                display: "flex",
                flexDirection: "column",
                maxWidth: "calc(100% - 80px)",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ color: "#343434", fontWeight: "medium" }}
              >
                {item.menu.name}
              </Typography>
              {item.option.map((option, index) => (
                <Typography
                  key={index} // Make sure to specify a unique key for each element when mapping
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    width: "80%",
                    height: "50%",
                    fontWeight: "medium",
                    color: "#797979",
                  }}
                >
                  {option}
                </Typography>
              ))}

              {item.note !== "" && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: "14px", mt: "4px" }}
                >
                  Note : {item.note}
                </Typography>
              )}

              <Typography
                onClick={() => setIsModalOpen(true)}
                sx={{
                  color: "#58AC60",
                  fontSize: "12px",
                  fontWeight: "medium",
                  cursor: "pointer",
                  width: "fit-content",
                }}
              >
                แก้ไข
              </Typography>
            </Box>

            <Box
              sx={{ mt: "4px", display: "flex", flexDirection: "row", gap: 1 }}
            >
              <Typography variant="body2" color="#343434" fontSize={"16px"}>
                {`฿ ${
                  item.price *
                  item.quantity
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }`}
              </Typography>
              <DeleteOutline
                onClick={() => setIsConfirmDeleteOpen(true)}
                sx={{ fontSize: "20px", cursor: "pointer", color: "#FF2C2C" }}
              />
            </Box>

            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Avatar
                onClick={() => handleQuantityChange(index, -1)}
                sx={{
                  backgroundColor: "#F1F1F1",
                  fontSize: "30px",
                  width: "25px",
                  height: "25px",
                  color: "black",
                  cursor: "pointer",
                }}
              >
                {item.quantity > 1 ? (
                  "-"
                ) : (
                  <DeleteOutline sx={{ fontSize: "20px" }} />
                )}
              </Avatar>
              <Typography>{item.quantity}</Typography>

              <AddCircleIcon
                onClick={() => handleAddButton()}
                sx={{
                  color: isAddDisabled ? "#E0E0E0" : "#FF961B",
                  fontSize: "30px",
                  cursor: isAddDisabled ? "not-allowed" : "pointer",
                }}
              />
            </Box> */}
          </Box>
        </Box>
      </Card>
      <MenuEditShopCardModal
        items={item.menu}
        open={isModalOpen}
        options={item.option}
        setOpen={setIsModalOpen}
        handleCloseModal={() => setIsModalOpen(false)}
        cartList={cartList}
        setCartList={setCartList}
        isAddDisabled={isAddDisabled}
        setIsAddDisabled={setIsAddDisabled}
        count={item.quantity}
      />
      <ModalConfirmDelete
        open={isConfirmDeleteOpen}
        onClose={() => setIsConfirmDeleteOpen(false)}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default CartItem;
