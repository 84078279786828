import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DefaultProfile from "../../assets/images/pompkins_food_logo.png";
import StarIcon from "@mui/icons-material/Star";
import { LocationOnOutlined, StarOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getMerchantById,
  getRatingByMerchantUUID,
} from "../../libs/merchantAPI/merchantAPI";
import LoadingScreen from "../../components/LoadingScreen";
import ReivewOrderModal from "./ReivewOrderModal";
import { useGuestLogin } from "../../stores/useGuestLogin";
import { useMerchantData } from "../../stores/useMerchantData";

function PastOrderTiles({ order }) {
  const [reviewOpen, setReviewOpen] = useState(false);
  const { t } = useTranslation();
  const navigator = useNavigate();
  const { isGuest } = useGuestLogin();
  const review = order.review;
  // const bmerchant = bMerchantData.find(
  //   (item) => item.name === order.merchant_name
  // );
  const bmerchant = order.merchant.bmerchant;
  const merchant = order.merchant;
  const { merchantData, isFetch } = useMerchantData(
    merchant.merchant_id,
    bmerchant.uuid
  );

  const hasReview = review?.customer_comment !== null || review?.point !== null;

  // useEffect(() => {
  //   const bmerchant = bMerchantData.find(
  //     (item) => item.name === order.merchant_name
  //   );
  //   setMerchantId(bmerchant.merchant_id);
  //   setBMerchants(bmerchant);
  //   setBMerchantId(bmerchant.id);
  //   getMerchantById(bmerchant.merchant_id)
  //     .then((res) => {
  //       setMerchantData(res.data);
  //       setIsFetch(true);
  //     })
  //     .catch((error) => {});
  // }, [bMerchantData, order, order.merchant_name]);

  // useEffect(() => {
  //   console.log("order", order);
  //   console.log("merchantData", merchantData);
  //   console.log("bMerchantData", bmerchant);
  // }, []);

  if (!isFetch) {
    return <LoadingScreen />;
  }
  return (
    <>
      {isFetch && (
        <Box sx={{ width: "100%", height: "130px" }}>
          <ReivewOrderModal
            open={reviewOpen}
            data={{
              order: order,
              bmerchantData: bmerchant,
              logo: order.merchant_logo ?? DefaultProfile,
            }}
            onClose={() => setReviewOpen(false)}
          />
          <Box
            onClick={() => {
              navigator(
                `/order/bill/${bmerchant.merchant_id}/${bmerchant.id}/${order.transaction_uuid}`
              );
            }}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              backgroundColor: "white",
              gap: "18px",
              mt: 1,
              p: 1,
              borderRadius: "15px",
              cursor: "pointer",
              position: "relative",
              boxShadow: 1,
              ":before": {
                content: "''",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: "15px",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                opacity: 0,
                transition: "opacity 0.3s ease-in-out",
              },
              ":hover::before": {
                opacity: 1,
                borderRadius: "15px",
              },
            }}
          >
            <Box sx={{ position: "relative", width: "100px" }}>
              <Box
                alt="food"
                component="img"
                src={merchant.logo ?? DefaultProfile}
                sx={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "15px",
                  cursor: "pointer",
                  objectFit: merchant.logo ? "contain" : "cover",
                }}
              />
            </Box>
            <Box width={"100%"}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  noWrap
                  fontSize={"16px"}
                  fontWeight={700}
                  sx={{ textOverflow: "ellipsis", mr: "4px" }}
                >
                  {merchant.merchant_name}
                </Typography>
                <StarIcon
                  sx={{ color: "rgb(250, 175, 0)", fontSize: "20px" }}
                />
                <Typography fontSize={"12px"} alignSelf={"center"}>
                  {" "}
                  {merchant.rating ? merchant.rating.toFixed(2) : "0.0"}
                </Typography>
              </Box>
              <Typography
                className="line-clamp-2"
                variant="body2"
                color="textSecondary"
                sx={{ width: "50%" }}
              >
                <LocationOnOutlined
                  sx={{ color: "rgba(0, 0, 0, 0.6)", ml: "-5px" }}
                />
                {merchant.location.address.address_formatted}
              </Typography>
              {/* <Typography variant="body2" color="textSecondary">
                <DeliveryDining
                  sx={{ color: "rgba(0, 0, 0, 0.6)", mr: "5px" }}
                />
                {distance} Km
              </Typography> */}
              <Box
                display="flex"
                flexDirection={"row"}
                alignItems={"end"}
                gap={1}
              >
                <Typography fontSize={"12px"} color="textSecondary">
                  {merchant.shop_type ?? "ร้านอาหารทั่วไป"}
                </Typography>
              </Box>
            </Box>
          </Box>
          {!isGuest && (
            <Box
              width={"30%"}
              sx={{
                position: "relative",
                top: "-80px",
                left: "68%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <Button
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  setReviewOpen(true);
                }}
                fullWidth
                sx={{
                  borderRadius: "10px",
                  p: "10px",
                  backgroundColor: "white",
                  fontSize: ["12px", "14px", "14px"],
                }}
                startIcon={hasReview ? <StarIcon /> : <StarOutline />}
              >
                รีวิวร้านค้า
              </Button>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

export default PastOrderTiles;
