import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Card,
  CardActionArea,
  Avatar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DefaultProfile from "../assets/images/pompkins_food_logo.png";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MenuShopCardModal from "./MenuShopCardModal";
import ModalImage from "./ModalImage";

function MenuShopCard({
  items,
  options,
  cartList,
  setCartList,
  onIncrement,
  onDecrement,
  openNoPayment,
  openNoService = true,
  openCloseModal = true,
  sx,
}) {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [imageModal, setImageModal] = useState(false);
  const [isAddDisabled, setIsAddDisabled] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isSmallScreen = useMediaQuery("(max-width:425px)");

  useEffect(() => {
    const totalQuantity = cartList?.reduce((total, cartItem) => {
      if (cartItem.menu.id === items.id) {
        return total + parseInt(cartItem.quantity, 10);
      }
      return total;
    }, 0);

    setCount(totalQuantity);
  }, [cartList, items.id, items.limited, items.existed]);

  useEffect(() => {
    setIsAddDisabled(items.limited && count >= items.existed);
  }, [count, isAddDisabled, items.existed, items.limited]);

  const handleOpenModal = (items) => {
    setOpen(true);
  };

  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") {
      setOpen(false);
    }
  };

  const isActionDisabled = () =>
    isAddDisabled || !openNoPayment || !openNoService || !openCloseModal;

  return (
    <>
      <ModalImage
        open={imageModal}
        onClose={() => setImageModal(false)}
        imageUrl={items.image === "" ? DefaultProfile : items.image}
      />
      <MenuShopCardModal
        items={items}
        options={options}
        open={open}
        setOpen={setOpen}
        handleCloseModal={handleCloseModal}
        cartList={cartList}
        setCartList={setCartList}
        isAddDisabled={isAddDisabled}
        setIsAddDisabled={setIsAddDisabled}
        count={count}
      />
      <Card
        sx={{
          display: "flex",
          borderRadius: isSmallScreen ? "24px" : "32px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          transition: "box-shadow 0.3s ease, transform 0.3s ease",
          "&:hover": {
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
            transform: "scale(1.02)",
          },
          ...sx,
        }}
      >
        <CardActionArea
          sx={{
            display: "flex",
            justifyContent: "space-between",
            cursor: isActionDisabled() ? "not-allowed" : "pointer",
            height: "100%",
            opacity: isAddDisabled ? 0.5 : 1,
            padding: isSmallScreen ? "8px" : "16px",
          }}
          disableRipple
          onClick={isActionDisabled() ? null : () => handleOpenModal(items)}
        >
          <Box
            alt={items.name}
            component="img"
            src={
              items.image === "" || items.image === null
                ? DefaultProfile
                : items.image + "?"
            }
            loading="lazy"
            title="Food Image"
            sx={{
              width: isSmallScreen ? "80px" : "136px",
              height: isSmallScreen ? "80px" : "136px",
              borderRadius: isSmallScreen ? "16px" : "24px",
              objectFit: items.image ? "cover" : "contain",
              overflow: "hidden",
              flexShrink: 0,
            }}
          />

          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              pl: isSmallScreen ? 1 : 2,
              minWidth: 0,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                noWrap
                sx={{
                  fontSize: isSmallScreen
                    ? "0.8rem"
                    : isMobile
                    ? "0.9rem"
                    : isTablet
                    ? "1rem"
                    : "1.1rem",
                  fontWeight: "bold",
                }}
              >
                {items.name}
              </Typography>

              <Typography
                className="text-primary"
                sx={{
                  fontSize: isSmallScreen
                    ? "1rem"
                    : isMobile
                    ? "1.1rem"
                    : isTablet
                    ? "1.25rem"
                    : "1.4rem",
                  fontWeight: "bold",
                  color: "#ff9a00",
                }}
              >
                {items.price
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                {items.price === "" ? "" : " ฿"}
              </Typography>

              {isAddDisabled && (
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: isSmallScreen
                      ? "0.7rem"
                      : isMobile
                      ? "0.8rem"
                      : isTablet
                      ? "0.9rem"
                      : "1rem",
                    fontWeight: "bold",
                  }}
                >
                  สินค้าหมดชั่วคราว
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: isSmallScreen ? 1 : 2,
              }}
            >
              {count !== 0 ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    onClick={(e) => {
                      e.stopPropagation();
                      onDecrement();
                    }}
                    sx={{
                      backgroundColor: "#F1F1F1",
                      fontSize: isSmallScreen ? "16px" : "20px",
                      width: isSmallScreen ? "20px" : "24px",
                      height: isSmallScreen ? "20px" : "24px",
                      cursor: "pointer",
                      color: "black",
                      "&:active": {
                        transform: "scale(1.05)",
                      },
                    }}
                  >
                    -
                  </Avatar>
                  <Typography sx={{ mx: 1, fontWeight: 600 }}>
                    {count}
                  </Typography>
                  <AddCircleIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!isActionDisabled()) onIncrement();
                    }}
                    sx={{
                      color: isActionDisabled() ? "#E0E0E0" : "#FF961B",
                      fontSize: isSmallScreen ? "24px" : "28px",
                      cursor: isActionDisabled() ? "not-allowed" : "pointer",
                      "&:active": {
                        transform: "scale(1.05)",
                      },
                    }}
                  />
                </Box>
              ) : (
                <AddCircleIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!isActionDisabled()) handleOpenModal(items);
                  }}
                  sx={{
                    color: isActionDisabled() ? "#E0E0E0" : "#FF961B",
                    fontSize: isSmallScreen ? "24px" : "28px",
                    cursor: isActionDisabled() ? "not-allowed" : "pointer",
                    "&:active": {
                      transform: "scale(1.05)",
                    },
                  }}
                />
              )}
            </Box>
          </Box>
        </CardActionArea>
      </Card>
    </>
  );
}

export default MenuShopCard;
