import React, { useEffect } from "react";
import {
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Divider,
  RadioGroup,
  Radio,
  TextField,
  Button,
  Checkbox,
  Avatar,
  useTheme,
  useMediaQuery,
  SwipeableDrawer,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Close, AddCircle, KeyboardArrowUp } from "@mui/icons-material";
import DefaultProfile from "../assets/images/pompkins_food_logo.png";
import zIndex from "@mui/material/styles/zIndex";

function MenuShopCardModal({
  items,
  open,
  setOpen,
  handleCloseModal,
  cartList,
  setCartList,
  isAddDisabled,
  setIsAddDisabled,
  count,
}) {
  const { t } = useTranslation();
  // console.log(items);
  const [cartNote, setCartNote] = React.useState("");
  const [optionList, setOptionList] = React.useState([]);
  const [cartQuantity, setCartQuantity] = React.useState(1);
  const [priceOffsetList, setPriceOffsetList] = React.useState([]);
  const [priceOffset, setPriceOffset] = React.useState(0);
  const [isSelectedOptions, setIsSelectedOptions] = React.useState(true);
  const [isAddDisabledModal, setIsAddDisabledModal] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const DialogComponent = isMobile ? SwipeableDrawer : Dialog;

  const handleAddCart = (items) => {
    if (items.limited && count + cartQuantity > items.existed) {
      setIsAddDisabledModal(true);
      return;
    }
    let menuToCart = {
      menu: items,
      quantity: cartQuantity === 0 ? 1 : cartQuantity,
      option: optionList,
      note: cartNote,
      price: items.price + priceOffset,
    };
    setCartList([...cartList, menuToCart]);
    handleClose();
  };

  useEffect(() => {
    if (items.options) {
      const missingRequiredOptions = items.options.some((option) => {
        if (option.required) {
          const selectedOption = optionList.find((selected) =>
            selected.startsWith(`${option.name}: `)
          );
          return !selectedOption;
        }
        return false;
      });
      if (missingRequiredOptions) {
        setIsSelectedOptions(false);
      } else {
        setIsSelectedOptions(true);
      }
    }
  }, [items.options, optionList]);

  const handleClose = () => {
    setOptionList([]);
    setCartQuantity(1);
    setPriceOffset(0);
    setPriceOffsetList([]);
    setCartNote("");
    setOpen(false);
  };

  const handleQuantityChange = (delta) => {
    const newQuantity = cartQuantity + delta;
    if (
      newQuantity > 0 &&
      (!items.limited || count + newQuantity <= items.existed)
    ) {
      setCartQuantity(newQuantity);
    }
  };
  const handleChangeOptionRadio = (mOption, name, price) => {
    const option = `${mOption}: ${name}`;

    // Check if an option with the same mOption already exists in optionList
    const existingOptionIndex = optionList.findIndex((optionItem) =>
      optionItem.startsWith(`${mOption}: `)
    );

    // Initialize an updated list from the current optionList
    let updatedOptionList = [...optionList];

    // If an existing option is found, remove it
    if (existingOptionIndex !== -1) {
      updatedOptionList.splice(existingOptionIndex, 1);
    }

    // Add the new option to the updated list
    updatedOptionList.push(option);

    // Update the optionList state
    setOptionList(updatedOptionList);

    // Prepare the price offset object
    const priceObject = {
      mOption,
      sOption: name,
      price,
    };

    // Check if the price offset for the current mOption exists
    const existingPriceOffsetIndex = priceOffsetList.findIndex(
      (priceOffset) => priceOffset.mOption === mOption
    );

    // Initialize a new price offset list from the current priceOffsetList
    let newPriceOffsetList = [...priceOffsetList];

    // If a price offset exists, update it, otherwise add a new one
    if (existingPriceOffsetIndex !== -1) {
      newPriceOffsetList[existingPriceOffsetIndex] = priceObject;
    } else {
      newPriceOffsetList.push(priceObject);
    }

    // Update the priceOffsetList state
    setPriceOffsetList(newPriceOffsetList);
  };
  // const handleOptionClick = (mOption, sOption, price) => {
  //   console.log(mOption, sOption, price);
  //   const option = `${mOption}: ${sOption}`;

  //   // Check if the option is already selected
  //   const isSelected = optionList.includes(option);

  //   // If the option is not selected, add it to the optionList
  //   if (!isSelected) {
  //     setOptionList([...optionList, option]);
  //   } else {
  //     // If the option is already selected, remove it from the optionList
  //     setOptionList(optionList.filter((item) => item !== option));
  //   }

  //   // Prepare the price offset object
  //   const priceObject = {
  //     mOption,
  //     sOption,
  //     price: parseInt(price, 10),
  //   };

  //   // Check if the price offset for the current mOption exists
  //   const existingPriceOffsetIndex = priceOffsetList.findIndex(
  //     (priceOffset) => priceOffset.mOption === mOption
  //   );

  //   // Initialize a new price offset list from the current priceOffsetList
  //   let newPriceOffsetList = [...priceOffsetList];

  //   // If a price offset exists, update it, otherwise add a new one
  //   if (existingPriceOffsetIndex !== -1) {
  //     newPriceOffsetList[existingPriceOffsetIndex] = priceObject;
  //   } else {
  //     newPriceOffsetList.push(priceObject);
  //   }

  //   // Update the priceOffsetList state
  //   setPriceOffsetList(newPriceOffsetList);
  // };

  const handleChangeOptionCheckbox = (mOption, sOption, selected, price) => {
    let option = mOption + ": " + sOption;
    let priceObject = {
      mOption: mOption,
      sOption: sOption,
      price: parseFloat(price, 10),
    };

    if (selected.target.checked) {
      setPriceOffsetList((prev) => [...prev, priceObject]);
      if (!optionList.includes(option)) {
        setOptionList((prev) => [...prev, option]);
      }
    } else {
      setPriceOffsetList((prev) =>
        prev.filter((p) => !(p.mOption === mOption && p.sOption === sOption))
      );
      setOptionList((prev) => prev.filter((item) => item !== option));
    }
  };

  useEffect(() => {
    if (priceOffsetList.length >= 0) {
      let priceOffset = 0;
      priceOffsetList.forEach((priceOffsetItem) => {
        priceOffset += parseFloat(priceOffsetItem.price);
      });
      setPriceOffset(priceOffset);
    }
  }, [priceOffsetList]);

  useEffect(() => {
    if (items.limited) {
      setIsAddDisabled(count + cartQuantity >= items.existed);
      setIsAddDisabledModal(count + cartQuantity >= items.existed);
    }
  }, [cartQuantity, count, items.limited, items.existed, setIsAddDisabled]);

  const dialogProps = isMobile
    ? {
        anchor: "bottom",
        open: open,
        onClose: handleClose,
        onOpen: () => setOpen(true),
        disableSwipeToOpen: false,
        PaperProps: {
          style: {
            // borderTopLeftRadius: "20px",
            // borderTopRightRadius: "20px",
            height: "100%",
            // maxHeight: "100vh",
          },
        },
      }
    : {
        open: open,
        onClose: handleCloseModal,
        fullWidth: true,
        sx: {
          maxHeight: "100vh",
          "& .MuiDialog-paper": {
            borderRadius: "20px",
          },
        },
      };

  return (
    <DialogComponent
      tabIndex={-1}
      disablePortal
      {...dialogProps}
      // open={open}
      // onClose={handleCloseModal}
      // fullWidth
      // sx={{
      //   maxHeight: "100vh",
      //   "& .MuiDialog-paper": {
      //     borderRadius: "20px",
      //   },
      // }}
    >
      <IconButton
        color="inherit"
        onClick={() => handleClose()}
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          borderRadius: "50%",
          p: 0.5,
          backgroundColor: "background.paper",
          transition: "transform 0.3s ease-in-out",
          "&:hover": {
            transform: "scale(1.1)",
            backgroundColor: "background.paper",
          },
        }}
      >
        <Close sx={{ color: "#FF961B" }} />
      </IconButton>

      <Box
        alt={items.name}
        component="img"
        src={items.image === "" || !items.image ? DefaultProfile : items.image}
        sx={{
          maxHeight: "30vh",
          objectFit: items.image ? "cover" : "contain",
        }}
      />

      <DialogTitle sx={{ typography: "h5" }}>{items.name}</DialogTitle>
      <DialogContent>
        {items.description !== "" && (
          <>
            <Typography sx={{ typography: "body1" }}>
              {items.description}
            </Typography>
            <Divider sx={{ my: 2 }} />
          </>
        )}
        {items.options !== null &&
          items.options.map((option, mOptionIndex) => (
            <Box
              key={option.id}
              sx={{ bgcolor: "#fff5e9", p: 2, borderRadius: "10px", mb: 2 }}
            >
              <Typography sx={{ typography: "h6" }}>{option.name}</Typography>
              {option.required ? (
                <RadioGroup
                  onChange={(event) => {
                    // handleChangeOptionRadio(option.id, selected)
                    let [name, price] = event.target.value.split("|||");
                    handleChangeOptionRadio(option.name, name, price);
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                    }}
                  >
                    {option.sub_option.map((subOption) => (
                      <Box
                        key={subOption.id}
                        onClick={() => {
                          if (
                            !optionList.includes(
                              `${option.name}: ${subOption.name}`
                            )
                          ) {
                            handleChangeOptionRadio(
                              option.name,
                              subOption.name,
                              subOption.price
                            );
                          }
                        }}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          cursor: "pointer",
                          ":hover": {
                            bgcolor: "#ffebcf",
                            borderRadius: "10px",
                          },
                        }}
                      >
                        <Radio
                          required={true}
                          value={`${subOption.name}|||${subOption.price}`}
                          checked={optionList.includes(
                            `${option.name}: ${subOption.name}`
                          )}
                          sx={{
                            color: "#FF961B",
                            "&.Mui-checked": {
                              color: "#FF961B",
                            },
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flex: 1,
                          }}
                        >
                          <Typography sx={{ typography: "body1", flex: 1 }}>
                            {subOption.name}
                          </Typography>
                          <Typography sx={{ typography: "body1", mr: "12px" }}>
                            {subOption.price}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </RadioGroup>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                  }}
                >
                  {option.sub_option.map((subOption) => (
                    <label
                      key={subOption.id}
                      htmlFor={`checkbox-${subOption.id}`}
                    >
                      <Box
                        key={subOption.id}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          cursor: "pointer",
                          ":hover": {
                            bgcolor: "#ffebcf",
                            borderRadius: "10px",
                          },
                        }}
                      >
                        <Checkbox
                          id={`checkbox-${subOption.id}`}
                          value={subOption.id}
                          checked={optionList.includes(
                            `${option.name}: ${subOption.name}`
                          )}
                          onChange={(selected) =>
                            handleChangeOptionCheckbox(
                              option.name,
                              subOption.name,
                              selected,
                              subOption.price
                            )
                          }
                          sx={{
                            color: "#FF961B",
                            "&.Mui-checked": {
                              color: "#FF961B",
                            },
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flex: 1,
                          }}
                        >
                          <Typography sx={{ typography: "body1", flex: 1 }}>
                            {subOption.name}
                          </Typography>
                          <Typography sx={{ typography: "body1", mr: "12px" }}>
                            {subOption.price}
                          </Typography>
                        </Box>
                      </Box>
                    </label>
                  ))}
                </Box>
              )}
            </Box>
          ))}
        {items.options !== null && <Divider sx={{ my: 2 }} />}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            mb: 2,
          }}
        >
          <Typography fontSize={"18px"} fontWeight={700} mb={"4px"}>
            {" "}
            หมายเหตุ
          </Typography>
          <Typography mb={1} fontSize={"14px"}>
            คุณสามารถใส่ข้อมูลเพิ่มเติมได้ที่นี่
          </Typography>

          <TextField
            multiline
            rows={2}
            placeholder={t("merchantPage.note")}
            value={cartNote}
            onChange={(e) => setCartNote(e.target.value)}
            variant="outlined"
            InputProps={{ sx: { borderRadius: "10px" } }}
            sx={{
              flex: 1,
              "& .MuiInput-underline:after": {
                borderBottomColor: "#FF961B",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ typography: "h6", mr: 2, fontSize: "1.5rem" }}>
            ราคารวม
          </Typography>

          <Typography sx={{ typography: "h6", fontSize: "1.5rem" }}>
            {((items.price + priceOffset) * cartQuantity)
              .toFixed(2)
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            {items.price === "" ? "" : " "}
            {t("merchantPage.baht")}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: "24px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
            my: 4,
          }}
        >
          <Avatar
            onClick={() => handleQuantityChange(-1)}
            sx={{
              backgroundColor: "#F1F1F1",
              fontSize: "30px",
              width: "25px",
              height: "25px",
              color: "black",
              cursor: "pointer",
            }}
          >
            -
          </Avatar>
          <Typography>{cartQuantity}</Typography>

          <AddCircle
            onClick={() => handleQuantityChange(1)}
            sx={{
              color:
                isAddDisabled || isAddDisabledModal ? "#E0E0E0" : "#FF961B",
              fontSize: "30px",
              cursor:
                isAddDisabled || isAddDisabledModal ? "not-allowed" : "pointer",
            }}
          />
        </Box>
        <Button
          variant="contained"
          fullWidth
          disabled={!isSelectedOptions}
          sx={{
            backgroundColor: !isSelectedOptions ? "#B0B0B0" : "#FF961B",
            borderRadius: "30px",
            padding: "6px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
          }}
          onClick={() => handleAddCart(items)}
        >
          <Typography
            sx={{
              typography: "body1",
              color: "#FFFFFF",
            }}
          >
            {t("merchantPage.addToCart")}
          </Typography>
        </Button>
      </DialogActions>
    </DialogComponent>
  );
}

export default MenuShopCardModal;
