import React, { useState, useRef } from "react";
import TextField from "@mui/material/TextField";

const OTPInput = ({ onOTPChange }) => {
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
  const handleKeyDown = (e, index) => {
    // Allow only numeric key presses (0-9) and Backspace (8)
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) || // Main keyboard 0-9
        (e.keyCode >= 96 && e.keyCode <= 105) || // Numpad 0-9
        e.keyCode === 8 || // Backspace
        e.keyCode === 46
      )
    ) {
      // Delete
      e.preventDefault();
    }
  };

  const handleInputChange = (e, index) => {
    const newOTP = [...otp];
    newOTP[index] = e.target.value;
    setOTP(newOTP);

    if (e.target.value && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    } else if (!e.target.value && index > 0) {
      inputRefs[index - 1].current.focus();
    }
    const otpValue = newOTP.join("");
    onOTPChange(otpValue);
  };

  return (
    <div>
      {otp.map((value, index) => (
        <TextField
          key={index}
          type="text"
          variant="standard"
          margin="dense"
          value={value}
          color="warning"
          onChange={(e) => handleInputChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          sx={{ p: 1, width: "16%" }}
          inputProps={{
            maxLength: 1,
            style: { textAlign: "center", fontSize: "1.5rem" },
          }}
          inputRef={inputRefs[index]}
        />
      ))}
    </div>
  );
};

export default OTPInput;
