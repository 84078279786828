import { axiosInstance } from "../axios";

// UNUSED API
export const getOrders = (uuid) => {
  if (!uuid) {
    console.error("UUID is missing or invalid");
    return;
  }
  console.log("uuid", uuid);
  return axiosInstance.get(`/web/allOrder/${uuid}?page=activity`);
};

export const getOrders_history = (uuid) => {
  if (!uuid) {
    console.error("UUID is missing or invalid");
    return;
  }
  console.log("uuid", uuid);
  return axiosInstance.get(`/web/allOrder/${uuid}?page=history`);
};

// USED API
export const getOrderV2 = (data) => {
  return axiosInstance.post(`/v2/web/customer/allOrder`, data);
};

export const getOrdersByOrders_uuid = (orderUuid) => {
  return axiosInstance.get(`/web/order/${orderUuid}`);
};

export const updateOrdersStatus = (orderUuid, status) => {
  return axiosInstance.patch(`/web/orders/${orderUuid}`, status);
};

export const getOrderByTableNumber = (merchantUuid, tableNumber) => {
  return axiosInstance.get(
    `/web/order/table-number/${merchantUuid}?tableNumber=${tableNumber}&status=unsuccess&payment_status=PENDING`
  );
};

export const getOrderByMerchantUuid = (merchantUuid) => {
  return axiosInstance.get(`/web/orders/${merchantUuid}`);
};
