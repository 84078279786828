// CartPage.js
import React, { useState, useEffect } from "react";
import CartItem from "../../components/cart/CartItem"; // Make sure this path is correct
import SlidingButtonGroup from "../../components/cart/SlidingButtonGroup"; // Ensure this is the correct path
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  TextField,
  Divider,
  InputAdornment,
  Container,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useLocationStore } from "../../stores/useLocationStore";
import { useAuthStore } from "../../stores/useAuthStore";
import { useGuestLogin } from "../../stores/useGuestLogin";
import TextInput from "../../components/TextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { set, useForm } from "react-hook-form";
import { cartDetailScheme } from "../../libs/authScheme";
import LoadingScreen from "../../components/LoadingScreen";
import { DistanceCalculator } from "../../utils/calDistance";
import ModalPriceError from "../../components/ModalPriceError";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import PaymentOptions from "./PaymentOptions";
import { paymentData } from "./paymentData";
import useOrderFromGoogle from "../../stores/useOrderFromGoogle";
import useFetchMerchantData from "../../stores/useFetchMerchantData";
import useAvailablePaymentList from "../../stores/useAvailablePaymentList";
import useDeliveryFee from "../../stores/useDeliveryFee";
import useOrderData from "../../stores/useOrderData";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import DeliveryDiningOutlinedIcon from "@mui/icons-material/DeliveryDiningOutlined";
import CouponModal from "./CouponModal";
import MerchantCloseModal from "../Merchant/MerchantCloseModal";
import { isMerchantOpen } from "../../libs/isMerchantOpen";

const CartPage = () => {
  const navigator = useNavigate();
  const { t } = useTranslation();

  const { merchant_id, bmerchant_id } = useParams();
  const [searchParams] = useSearchParams();
  const utmSource = searchParams.get("utm_source");
  const utmMedium = searchParams.get("utm_medium");
  const utmCampaign = searchParams.get("utm_campaign");
  const orderTypeParams = searchParams.get("order_type");

  const [orderType, setOrderType] = useState(orderTypeParams || "pickup");
  const [peopleCount, setPeopleCount] = useState(0);
  const [paymentType, setPaymentType] = useState("");
  const [timeToGet, setTimeToGet] = useState("");
  const [timeToReserve, setTimeToReserve] = useState("");

  const [distance, setDistance] = useState(0);
  const [deliverNote, setDeliverNote] = useState("");
  const [openPriceError, setOpenPriceError] = useState(false);
  const [error, setError] = useState({
    errortimeToGet: null,
    errorPeopleCount: null,
    errortimeToReserve: null,
    errorDistance: null,
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [openCouponModal, setOpenCouponModal] = useState(false);
  const [openCloseModal, setOpenCloseModal] = useState(false);

  const { user, isAuthenticated } = useAuthStore();
  const { guest_user, isGuest } = useGuestLogin();
  const { currentAddress, currentLocation, openModal } = useLocationStore();
  const { bmerchantData, merchantData, bmerchantService, isFetch } =
    useFetchMerchantData(bmerchant_id, merchant_id);
  const availablePaymentList = useAvailablePaymentList(
    bmerchantService,
    orderType
  );
  const { deliveryFee, error: deliveryError } = useDeliveryFee(
    orderType,
    bmerchantService,
    distance
  );
  const { orderdataResponse, orders, setOrders } = useOrderData(bmerchant_id);

  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    values: {
      name: user?.first_name || guest_user?.name,
      phone: user?.phone || guest_user?.phone,
    },
    mode: "all",
    resolver: yupResolver(cartDetailScheme),
  });

  useOrderFromGoogle(merchant_id, bmerchant_id, orderType);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/merchant/${merchant_id}/${bmerchant_id}/cartorder`,
      title: "CartPage",
    });
  }, []);

  useEffect(() => {
    if (orderType === "delivery") {
      const calculateDistance = async () => {
        const location = {
          lat: bmerchantData.latitude,
          lng: bmerchantData.longitude,
        };
        const { distance } = DistanceCalculator(currentLocation, location);
        setDistance(distance);
      };
      calculateDistance();
    }
  }, [
    bmerchantData.latitude,
    bmerchantData.longitude,
    currentLocation,
    orderType,
  ]);

  const handleOrderTypeChange = (newOrderType) => {
    if (utmSource && utmMedium && utmCampaign && orderTypeParams) {
      navigate(
        `/merchant/${merchant_id}/${bmerchant_id}/cartorder/?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&order_type=${newOrderType}`
      );
    }
    setOrderType(newOrderType);
  };

  const handlePickupTimeSelected = (time) => {
    // Handle the pickup time selection
    setError({ ...error, errortimeToGet: null });
    setTimeToGet(time);
  };

  const handleReserveTimeSelected = (time) => {
    // Handle the reserve time selection
    setError({ ...error, errortimeToReserve: null });
    setTimeToReserve(time);
  };

  const handlePeopleCountSelected = (count) => {
    // Handle the people count selection
    setError({ ...error, errorPeopleCount: null });
    setPeopleCount(count);
  };

  const handleGotoCheckoutPage = async (data) => {
    if (!isMerchantOpen(bmerchantData.open_time, bmerchantData.close_time)) {
      setOpenCloseModal(true);
      return;
    }
    if (totals < 1) {
      setOpenPriceError(true);
      return;
    }
    let hasError = false;
    setIsSubmit(true);
    if (orderType === "pickup" && timeToGet === "") {
      setError({ ...error, errortimeToGet: "กรุณาเลือกเวลาที่จะรับ" });
      hasError = true;
    }

    if (orderType === "reserve") {
      let errortimeToReserve = null;
      let errorPeopleCount = null;
      if (timeToReserve === "") {
        errortimeToReserve = "กรุณาเลือกเวลาที่จะจอง";
        hasError = true;
      }
      if (peopleCount.toString() === "0") {
        errorPeopleCount = "กรุณาใส่จํานวนคน";
        hasError = true;
      } else if (peopleCount < 0) {
        errorPeopleCount = "จํานวนคนต้องมากกว่า 0";
        hasError = true;
      }
      setError({ ...error, errortimeToReserve, errorPeopleCount });
    }

    if (hasError) {
      return;
    }

    const formattedOrders = orders.map((order) => ({
      menu_id: parseInt(order.menu.id, 10),
      menu_name: order.menu.name,
      quantity: parseInt(order.quantity, 10),
      note: order.note || "-",
      price: order.price,
      option: order.option,
      image: order.menu.image,
    }));
    const total_order = formattedOrders.reduce(
      (total, order) => total + parseInt(order.quantity, 10),
      0
    );
    const time_to_get = orderType === "reserve" ? timeToReserve : timeToGet;
    const orderFrom =
      utmSource && utmMedium && utmCampaign ? "GOOGLE_MAPS" : "POMPKINS";
    // Construct the order data
    const orderData = {
      customer_uuid: orderdataResponse.customer_uuid,
      customer_name: data.name,
      total_order: total_order,
      phone: data.phone, // Replace with actual customer UUID
      bmerchant_uuid: orderdataResponse.merchant_uuid, // Replace with actual merchant UUID
      time_to_get: time_to_get, // Use the selected time or null
      table_number: orderType === "reserve" ? peopleCount : null,
      transaction_uuid: null, // This can be dynamic based on your application logic
      order_type: orderType.toUpperCase(), // Order type in uppercase
      order_detail: formattedOrders, // The formatted order details
      deliver_fee: orderType === "delivery" ? deliveryFee : 0, // Delivery fee (can be dynamic)
      discount: 0, // Discount (can be dynamic)
      order_distance: orderType === "delivery" ? parseFloat(distance) : 0, // Distance
      total_amount: total, // Total amount calculated from orders
      payment_method: paymentType, // Payment method (can be dynamic)
      deliver_address: orderType === "delivery" ? currentAddress : null, // Delivery address (can be dynamic)
      deliver_note: orderType === "delivery" ? deliverNote ?? "" : null,
      order_from: orderFrom,
    };

    localStorage.setItem(
      `checkout_data_${bmerchant_id}`,
      JSON.stringify(orderData)
    );
    if (utmSource && utmMedium && utmCampaign && orderType) {
      navigator(
        `/merchant/${merchant_id}/${bmerchant_id}/checkout?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&order_type=${orderType}`,
        {
          relative: "path",
        }
      );
    } else {
      navigator(`/merchant/${merchant_id}/${bmerchant_id}/checkout`, {
        relative: "path",
      });
    }
  };

  const totals = orders.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );
  const subtotal = bmerchantService.vat ? totals / 1.07 : totals;
  const vat = bmerchantService.vat ? totals - subtotal : 0;
  const total = totals + deliveryFee;

  const handleQuantityChange = (index, delta) => {
    setOrders((orders) => {
      const newOrders = [...orders];
      const currentQuantity = parseInt(newOrders[index].quantity, 10);
      const newItemQuantity = currentQuantity + delta;

      if (newItemQuantity > 0) {
        newOrders[index].quantity = newItemQuantity;
      } else {
        // Remove the item if the new quantity is 0 or less
        newOrders.splice(index, 1);
      }
      return newOrders;
    });
  };

  useEffect(() => {
    const cartDataString = localStorage.getItem(`merchant_${bmerchant_id}`);
    let cartData = cartDataString ? JSON.parse(cartDataString) : {};

    localStorage.setItem(
      `merchant_${bmerchant_id}`,
      JSON.stringify({
        ...cartData,
        totalPrice: subtotal,
        cartList: orders,
      })
    );
  }, [orders]); // Only re-run the effect if orders changes

  useEffect(() => {
    localStorage.setItem(
      `order_merchant_${bmerchant_id}`,
      JSON.stringify({
        ...orderdataResponse,
        order_detail: orders,
      })
    );
  }, [orders]); // Only re-run the effect if orders changes

  if (!isFetch) {
    return <LoadingScreen />;
  }
  if (orders.length === 0) {
    navigator(-1);
  }
  if (!isAuthenticated && !isGuest) {
    navigator(-1);
  }

  return (
    <>
      <Helmet>
        <title>
          {t("pompkins")} - {t("cart.order")}
        </title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link rel="canonical" href={`${window.location.href}`} />
        <meta property="og:url" content="https://pompkins.com/merchant" />
      </Helmet>
      <Container maxWidth="lg" sx={{ py: { xs: 2, sm: 3, md: 4 } }}>
        {openPriceError && (
          <ModalPriceError open={openPriceError} setOpen={setOpenPriceError} />
        )}
        <Card sx={{ borderRadius: { xs: "10px", sm: "15px" }, boxShadow: 3 }}>
          <CardContent sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
            <SlidingButtonGroup
              service={bmerchantService}
              bmerchantData={bmerchantData}
              onOrderTypeChange={handleOrderTypeChange}
              onPickupTimeSelected={handlePickupTimeSelected}
              onReserveTimeSelected={handleReserveTimeSelected}
              onPeopleCountSelected={handlePeopleCountSelected}
              isSubmit={isSubmit}
              error={error}
              orderType={orderType}
              setOrderType={setOrderType}
              deliverData={{
                deliverNote: deliverNote,
                setDeliverNote: setDeliverNote,
              }}
              setPaymentType={setPaymentType}
            />
            <Typography
              variant="body2"
              sx={{ fontSize: { xs: "16px", sm: "18px", md: "20px" }, mb: 2 }}
              color="#343434"
              mb={1}
            >
              ชื่อร้านค้า: {merchantData.name}
            </Typography>

            {orderType === "pickup" && (
              <Box
                sx={{
                  mb: 3,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: { xs: "16px", sm: "18px" }, mb: 1 }}
                  color="#343434"
                >
                  รับอาหารได้ที่:
                </Typography>

                <TextField
                  multiline
                  fullWidth
                  disabled
                  value={bmerchantData.location}
                  InputProps={{
                    sx: { borderRadius: "15px", p: "8px 16px" },
                    startAdornment: (
                      <InputAdornment position="start" sx={{ mr: 2 }}>
                        <ShoppingBagOutlinedIcon
                          sx={{ fontSize: { xs: "24px", sm: "30px" } }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: { xs: "100%", sm: "90%", md: "80%" } }}
                />
              </Box>
            )}

            {orderType === "delivery" && (
              <Box
                sx={{
                  mb: 3,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: { xs: "16px", sm: "18px" }, mb: 1 }}
                  color="#343434"
                >
                  ที่อยู่การจัดส่ง:
                </Typography>

                <TextField
                  multiline
                  fullWidth
                  value={currentAddress}
                  onClick={() => openModal()}
                  sx={{
                    cursor: "pointer",
                    "& .MuiInputBase-input": {
                      cursor: "pointer",
                    },
                    width: { xs: "100%", sm: "90%", md: "80%" },
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: "15px",
                      p: "8px 16px",
                    },
                    startAdornment: (
                      <InputAdornment position="start" sx={{ mr: 2 }}>
                        <DeliveryDiningOutlinedIcon
                          sx={{ fontSize: { xs: "24px", sm: "30px" } }}
                        />
                      </InputAdornment>
                    ),
                    readOnly: true,
                  }}
                />
              </Box>
            )}
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 3 }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: { xs: "16px", sm: "18px" },
                    minWidth: "100px",
                  }}
                  color="#343434"
                >
                  ชื่อผู้รับ:
                </Typography>
                <TextInput
                  placeholder={"ชื่อผู้รับ"}
                  error={errors.name?.message}
                  {...register("name", { required: true })}
                  sx={{
                    flexGrow: 1,
                    "& .MuiOutlinedInput-root": {
                      height: { xs: "36px", sm: "40px" },
                      fontSize: { xs: "14px", sm: "16px" },
                      borderRadius: "8px",
                    },
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: { xs: "16px", sm: "18px" },
                    minWidth: "100px",
                  }}
                  color="#343434"
                >
                  เบอร์ติดต่อ:
                </Typography>
                <TextInput
                  placeholder={"เบอร์โทรศัพท์"}
                  error={errors.phone?.message}
                  {...register("phone", { required: true })}
                  sx={{
                    flexGrow: 1,
                    "& .MuiOutlinedInput-root": {
                      height: { xs: "36px", sm: "40px" },
                      fontSize: { xs: "14px", sm: "16px" },
                      borderRadius: "8px",
                    },
                  }}
                />
              </Box>
            </Box>

            {orderType === "delivery" && (
              <Box sx={{ mb: 3 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: { xs: "16px", sm: "18px" }, mb: 1 }}
                >
                  รูปแบบการจัดส่ง:{" "}
                  <span
                    style={{
                      color: "#58AC60",
                      fontWeight: "medium",
                      fontSize: "14px",
                    }}
                  >
                    ระบุโน้ตถึงไรเดอร์
                  </span>
                </Typography>

                <TextField
                  multiline
                  onChange={(e) => setDeliverNote(e.target.value)}
                  defaultValue={deliverNote}
                  placeholder="ex.แขวนไว้ที่ประตูรั้วได้เลยค่ะ , เดี๋ยวออกไปรับเองค่ะ , ฝากไว้กับลุงยามได้เลยค่ะ"
                  rows={3}
                  sx={{ width: { xs: "100%", sm: "90%", md: "80%" } }}
                  InputProps={{
                    sx: {
                      borderRadius: "15px",
                      p: "8px 16px",
                      fontSize: "14px",
                    },
                  }}
                />
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "18px", sm: "20px" } }}
                fontWeight={"medium"}
                color="#343434"
              >
                รายการอาหารที่สั่ง
              </Typography>
              <Button
                onClick={() =>
                  navigator(
                    `/merchant/${merchant_id}/${bmerchant_id}/${bmerchantData.name}`
                  )
                }
                sx={{
                  color: "#58AC60",
                  ":hover": {
                    backgroundColor: "#FFF7EB",
                  },
                }}
              >
                {t("cartOrder.addMenu")}
              </Button>
            </Box>

            <Box sx={{ mb: 3 }}>
              {orders.map((item, index) => (
                <CartItem
                  key={`${item.menu.id}-${index}`}
                  item={item}
                  index={index}
                  handleQuantityChange={handleQuantityChange}
                  cartList={orders}
                  setCartList={setOrders}
                />
              ))}
            </Box>

            <Divider sx={{ mb: 2 }} />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              <Typography sx={{ fontSize: { xs: "16px", sm: "18px" } }}>
                คูปอง
              </Typography>
              <Box>
                <Button
                  sx={{
                    color: "#797979",
                    p: "4px",
                    pl: "10px",
                    fontSize: { xs: "14px", sm: "16px" },
                    minWidth: 0,
                  }}
                  onClick={() => setOpenCouponModal(true)}
                >
                  {"ใช้้คูปอง >"}
                </Button>
              </Box>
            </Box>
            <Divider sx={{ mb: 2 }} />

            {/* ส่วนสรุป */}

            {orderType === "delivery" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    my: 1,
                  }}
                >
                  <Typography sx={{ fontSize: { xs: "14px", sm: "16px" } }}>
                    ระยะทาง
                  </Typography>
                  <Typography
                    sx={{ fontSize: { xs: "14px", sm: "16px" } }}
                  >{`${distance
                    .toString()
                    .replace(
                      /(\d)(?=(\d{3})+(?!\d))/g,
                      "$1,"
                    )} Km`}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <Typography sx={{ fontSize: { xs: "14px", sm: "16px" } }}>
                    ค่าจัดส่ง
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "14px", sm: "16px" } }}>
                    {error.errorDistance ? (
                      <span style={{ color: "red" }}>
                        {error.errorDistance}
                      </span>
                    ) : (
                      `฿ ${deliveryFee
                        .toFixed(2)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`
                    )}
                  </Typography>
                </Box>
              </>
            )}

            {/* ค่าอาหาร */}

            {/* {bmerchantService.vat && ( */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 1,
                color: "#343434",
              }}
            >
              {/* <Typography> {}ราคารวมก่อนภาษีมูลค่าเพิ่ม</Typography> */}
              <Typography sx={{ fontSize: { xs: "16px", sm: "18px" } }}>
                ค่าอาหาร
              </Typography>
              <Typography
                sx={{ fontSize: { xs: "16px", sm: "18px" } }}
              >{`฿ ${subtotal.toFixed(2)}`}</Typography>
            </Box>
            {/* )} */}

            {bmerchantService.vat && (
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
              >
                <Typography sx={{ fontSize: { xs: "14px", sm: "16px" } }}>
                  VAT7%
                </Typography>
                <Typography
                  sx={{ fontSize: { xs: "14px", sm: "16px" } }}
                >{`฿ ${vat
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</Typography>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
                color: "#58AC60",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  fontWeight: "medium",
                }}
              >
                ทั้งหมด
              </Typography>

              <Typography
                sx={{
                  fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  fontWeight: "medium",
                }}
              >{`฿ ${total
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</Typography>
            </Box>

            <Divider sx={{ mb: 2 }} />

            <PaymentOptions
              availablePaymentList={availablePaymentList}
              paymentData={paymentData}
              bmerchantService={bmerchantService}
              paymentType={paymentType}
              setPaymentType={setPaymentType}
            />

            {total < 20 && (
              <Typography
                color={"#FF2C2C"}
                sx={{ mb: 2, fontSize: { xs: "14px", sm: "16px" } }}
              >
                *ขั้นต่ำ 20 บาท
              </Typography>
            )}

            <Button
              variant="contained"
              color="primary"
              disabled={
                paymentType === "" ||
                (orderType === "delivery" && error.errorDistance) ||
                total < 20
              }
              fullWidth
              sx={{
                p: { xs: "10px", sm: "12px" },
                borderRadius: "2rem",
                fontSize: { xs: "16px", sm: "18px" },
              }}
              onClick={handleSubmit(handleGotoCheckoutPage)}
            >
              เช็คเอาท์
            </Button>
          </CardContent>
        </Card>
      </Container>

      <CouponModal
        open={openCouponModal}
        onClose={() => setOpenCouponModal(false)}
      />
      <MerchantCloseModal
        open={openCloseModal}
        setOpenClose={setOpenCloseModal}
        seeMenuButton={false}
      />
    </>
  );
};

export default CartPage;
