import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Box,
  Collapse,
  CardMedia,
  IconButton,
  Button,
  CardActionArea,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getMerchantById } from "../../libs/merchantAPI/merchantAPI";
function PastOrderItem({ order, bMerchantData }) {
  const [showDetails, setShowDetails] = useState(false);
  const [merchantData, setMerchantData] = useState();
  const [merchantId, setMerchantId] = useState();
  const type = order.order_type;
  const { t } = useTranslation();
  const navigator = useNavigate();
  useEffect(() => {
    const bmerchant = bMerchantData.find(
      (item) => item.name === order.merchant_name
    );
    setMerchantId(bmerchant.id);
    getMerchantById(bmerchant.id)
      .then((res) => {
        setMerchantData(res.data);
      })
      .catch((error) => {});
  }, [bMerchantData, order.merchant_name]);

  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
  };
  const subtotal = order.menu.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );
  const vatValues = subtotal * 0.07;
  return (
    <Card sx={{ marginBottom: 2, borderRadius: "15px" }}>
      <CardContent>
        <CardActionArea
          onClick={() => {
            navigator(`/order/bill/${merchantId}/${order.order_uuid}`);
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            {/* Image */}
            <Box
              sx={{
                justifyContent: "end",
                display: "flex",
                alignItems: "start",
              }}
            >
              <CardMedia
                component="img"
                loading="lazy"
                sx={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "10px",
                  objectFit: "cover",
                }}
                image={order["merchant_logo"]}
                alt={order["merchant_name"]}
              />
            </Box>

            {/* Restaurant Details */}
            <Box
              sx={{
                flex: 1,
                width: { xs: "34%", sm: "45%", md: "45%", lg: "32%" },
              }}
            >
              <Typography variant="h7" gutterBottom>
                {order["merchant_name"]}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {t("order.orderOn")} {order["order_date"]}
              </Typography>

              {type === "PICKUP" && (
                <Typography variant="body2" color="textSecondary">
                  {t("order.pickupOn")} {order["time_to_get"]}
                </Typography>
              )}

              <Box sx={{ paddingTop: "16px" }}>
                {order["menu"].map((item, index) => (
                  <Typography
                    key={index}
                    variant="body2"
                    sx={{ paddingBottom: "8px" }}
                  >
                    {item["quantity"]} x {item["menu_name"]}
                  </Typography>
                ))}
              </Box>
            </Box>

            {/* Total Price and Reorder Button */}
            <Box justifyContent="space-between">
              <Box
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    textAlign: "end",
                    paddingLeft: { sm: "10px", md: "40%" },
                  }}
                >
                  <Typography
                    variant="h7"
                    color="primary"
                    sx={{ alignItems: "space-between", textAlign: "end" }}
                  >
                    {order["total_amount"]}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardActionArea>
        <Box sx={{ display: "flex", justifyContent: "center", px: 2, my: 2 }}>
          <Button
            onClick={() => {
              if (merchantData) {
                navigator(`/merchant/${merchantData.id}/${merchantData.name}`);
              }
            }}
            fullWidth
            variant="contained"
            sx={{
              borderRadius: "2rem",
              fontSize: "14px",
              fontWeight: 500,
              px: 4,
              boxShadow: "0 3px 12px 0 rgba(255,161,9,.589)",
              ":hover": {
                boxShadow: 0,
              },
            }}
          >
            ออเดอร์อีกครั้ง
          </Button>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 1,
            cursor: "pointer",
          }}
          onClick={handleToggleDetails}
        >
          <Box>
            <Typography variant="h7" color="primary">
              {t("order.viewDetails")} {order["order_id"]})
            </Typography>
          </Box>
          <IconButton>
            {showDetails ? (
              <ExpandLessIcon color="primary" />
            ) : (
              <ExpandMoreIcon color="primary" />
            )}
          </IconButton>
        </Box>

        {/* Expanded Details Section */}
        <Collapse in={showDetails}>
          {type === "DELIVERY" && (
            <>
              <Box
                sx={{ marginTop: 2 }}
                display="flex"
                flexDirection="column"
                mt={2}
              >
                <Typography variant="body2">Delivered from :</Typography>
                <Typography variant="body2">
                  {order["merchant_location"]}
                </Typography>
                <Typography variant="body2">Delivered to :</Typography>
                <Typography variant="body2">
                  {order["deliver_address"]}
                </Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
            </>
          )}

          <Box>
            {order["menu"].map((item, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={1}
              >
                <Typography variant="body2">
                  {item["quantity"]} x {item["menu_name"]}
                </Typography>
                <Typography variant="body2">
                  {item["price"]
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
                  ฿
                </Typography>
              </Box>
            ))}

            <Divider sx={{ my: 2 }} />

            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2">Subtotal</Typography>
              <Typography variant="body2">{subtotal} ฿</Typography>
            </Box>
            {type === "DELIVERY" && (
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body2">Delivery fee</Typography>
                <Typography variant="body2">{50} ฿</Typography>
              </Box>
            )}

            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2">Discount</Typography>
              <Typography variant="body2">{order.promotion} ฿</Typography>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2">VAT (7%)</Typography>
              <Typography variant="body2">{vatValues.toFixed(1)} ฿</Typography>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1" fontWeight={500}>
                Total (incl. VAT)
              </Typography>
              <Typography variant="body1" fontWeight={500}>
                {order.total_amount} ฿
              </Typography>
            </Box>

            {/* <Box display="flex" justifyContent="space-between" mt={1}>
              <Typography variant="body2">Paid with</Typography>
              <Typography variant="body2"></Typography>
            </Box> */}
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  );
}

export default PastOrderItem;
