import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  Radio,
  useMediaQuery,
  Divider,
  Container,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { createOrder, updateOrderStatus } from "../../libs/orderAPI/orderAPI";
import {
  getBMerchantById,
  getMerchantById,
  getPaymentStatusList,
  getServiceBMerchant,
} from "../../libs/merchantAPI/merchantAPI";
import CheckOutItem from "../CartPage/CheckOutItem";
import { paymentData } from "../CartPage/paymentData";
import useModalLoading from "../../stores/useModalLoading";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import useFetchMerchantData from "../../stores/useFetchMerchantData";

const MerchantQrCheckoutPage = () => {
  const { t } = useTranslation();
  const [orderData, setOrderData] = useState({});
  const [orderType, setOrderType] = useState("");
  const [orders, setOrders] = useState([]);
  const navigator = useNavigate();
  const { merchant_id, bmerchant_id } = useParams();
  const [paymentType, setPaymentType] = useState("");
  const [availablePaymentList, setAvailablePaymentList] = useState([]);
  const { openModal, closeModal } = useModalLoading();
  const [isVerified, setIsVerified] = useState(false);

  const { bmerchantData, merchantData, bmerchantService, isFetch } =
    useFetchMerchantData(bmerchant_id, merchant_id);
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMdScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));

  const fontSize = isXsScreen ? 14 : isSmScreen ? 16 : 18;
  const titleFontSize = isXsScreen ? 20 : isSmScreen ? 24 : 28;

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/merchant-qr/${merchant_id}/${bmerchant_id}/checkout`,
      title: "MerchantQrCheckoutPage",
    });
  }, []);

  useEffect(() => {
    const orderDataString = localStorage.getItem(
      `checkout_data_${bmerchant_id}`
    );
    const orderData = JSON.parse(orderDataString);
    if (orderData) {
      setOrders(orderData.order_detail);
      setOrderData(orderData);
      setOrderType(orderData.order_type);
      setPaymentType(orderData.payment_method);
    } else {
      navigator("/food");
    }
  }, []);

  const handleSubmit = async () => {
    openModal();
    if (!paymentType) {
      closeModal();
      return null;
    } else {
      const body = {
        ...orderData,
        payment_method: paymentType,
      };
      createOrder(body)
        .then((res) => {
          const order_uuid = res.data.order_uuid;
          if (bmerchantService.auto_accept) {
            if (
              [
                "ALIPAY",
                "WECHAT_PAY",
                "SHOPEE_PAY",
                "THAI_QR",
                "THAI_QR_INET",
              ].some((method) => paymentType.includes(method))
            ) {
              updateOrderStatus(order_uuid, "MERCHANT CONFIRMED");
            } else if (paymentType === "CASH") {
              updateOrderStatus(order_uuid, "PURCHASED");
            }
          }
          navigator(
            `/order-status/${merchant_id}/${bmerchant_id}/${order_uuid}`
          );
          localStorage.removeItem(`order_merchant_${bmerchant_id}`);
          localStorage.removeItem(`merchant_${bmerchant_id}`);
          localStorage.removeItem(`checkout_data_${bmerchant_id}`);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          closeModal();
        });
    }
  };

  const totals = orders.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );
  const subtotal = bmerchantService.vat ? totals / 1.07 : totals;
  const vatValues = bmerchantService.vat ? totals - subtotal : 0;

  if (!orderData) {
    navigator(-1);
  }
  return (
    <>
      <Helmet>
        <title>{t("checkout")}</title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link rel="canonical" href={`${window.location.href}`} />
        <meta property="og:url" content="https://pompkins.com/merchant-qr" />
      </Helmet>
      <Container maxWidth="lg" sx={{ py: { xs: 2, sm: 3, md: 4 } }}>
        <Card sx={{ borderRadius: { xs: "10px", sm: "15px" }, boxShadow: 3 }}>
          <CardContent sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
            <Typography variant="h5" fontWeight={500} fontSize={titleFontSize}>
              เช็คเอาท์
            </Typography>
            <Typography variant="h6" fontWeight={"light"} fontSize={fontSize}>
              รายละเอียด
            </Typography>

            <Box sx={{ mb: 3 }}>
              <Typography
                variant="body1"
                fontSize={fontSize}
                color="#343434"
                fontWeight={500}
                gutterBottom
              >
                ชื่อร้านค้า: {merchantData.name}
              </Typography>

              <Typography
                variant="body1"
                fontSize={fontSize}
                color="#343434"
                fontWeight={500}
                gutterBottom
              >
                ชื่อผู้รับ:{" "}
                <span style={{ fontWeight: "lighter" }}>
                  {orderData.customer_name}
                </span>
              </Typography>
              <Typography
                variant="body1"
                fontSize={fontSize}
                color="#343434"
                fontWeight={500}
                gutterBottom
              >
                เบอร์ติดต่อ:{" "}
                <span style={{ fontWeight: "lighter" }}>{orderData.phone}</span>
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: fontSize + 2 }}
                fontWeight="medium"
                color="#343434"
              >
                รายการอาหารที่สั่ง
              </Typography>
              <Button
                onClick={() =>
                  navigator(
                    `/merchant/${merchant_id}/${bmerchant_id}/${bmerchantData.name}`
                  )
                }
                sx={{
                  color: "#58AC60",
                  ":hover": { backgroundColor: "#FFF7EB" },
                  fontSize: fontSize - 2,
                }}
              >
                {t("cartOrder.addMenu")}
              </Button>
            </Box>

            <Box sx={{ mb: 3 }}>
              {orders.map((item, index) => (
                <CheckOutItem key={item.menu_id} item={item} index={index} />
              ))}
            </Box>

            <Divider sx={{ my: 2 }} />

            {/* ส่วนสรุป */}
            <Box sx={{ mb: 3 }}>
              {/* {bmerchantService.vat && ( */}
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
              >
                {/* <Typography fontSize={fontSize}>
                  {" "}
                  {}ราคารวมก่อนภาษีมูลค่าเพิ่ม
                </Typography> */}
                <Typography sx={{ fontSize: fontSize - 2 }}>
                  {" "}
                  {}ค่าอาหาร
                </Typography>

                <Typography fontSize={fontSize - 2}>{`฿ ${subtotal.toFixed(
                  2
                )}`}</Typography>
              </Box>
              {/* )} */}

              {bmerchantService.vat && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                >
                  <Typography>VAT7%</Typography>
                  <Typography>{`฿ ${vatValues
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</Typography>
                </Box>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: fontSize, fontWeight: "lighter" }}
              >
                เลือกชำระโดย
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <img
                  src={
                    paymentData.find((p) => p.paymentType === paymentType)
                      ?.images
                  }
                  width={isXsScreen ? "24px" : "28px"}
                  height={isXsScreen ? "24px" : "28px"}
                  alt="payment"
                  loading="lazy"
                />
                <Typography fontSize={fontSize - 2}>
                  {
                    paymentData.find((p) => p.paymentType === paymentType)
                      ?.title
                  }
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 3,
                color: "#58AC60",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: fontSize + 4, fontWeight: "medium" }}
              >
                ทั้งหมด
              </Typography>
              <Typography sx={{ fontSize: fontSize + 4, fontWeight: "medium" }}>
                {`฿ ${totals
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}
              </Typography>
            </Box>

            <Button
              variant="contained"
              color="primary"
              disabled={paymentType === ""}
              fullWidth
              sx={{ mt: 2, p: "8px 22px", borderRadius: "2rem" }}
              onClick={handleSubmit}
            >
              ยืนยัน
            </Button>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default MerchantQrCheckoutPage;
