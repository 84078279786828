import React, { useCallback, useEffect, useState, createRef } from "react";
import {
  Box,
  Typography,
  IconButton,
  Toolbar,
  Button,
  Fab,
} from "@mui/material";
import DefaultProfile from "../../assets/images/pompkins_food_logo.png";
import DefaultBackground from "../../utils/defaultBackground";
import {
  getBMerchantById,
  getMenuByBMerchantId,
  getMerchantById,
  getCategoryByBMerchantId,
} from "../../libs/merchantAPI/merchantAPI";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useMobile } from "../../stores/useMobile";
import { ContentCopy } from "@mui/icons-material";
import MenuShopCategoryTab from "../../components/MenuShopCategoryTab";
import LoadingScreen from "../../components/LoadingScreen";
import { useAuthStore } from "../../stores/useAuthStore";
import ModalQrCode from "./ModalQrCode";
import QrMenuShopCard from "./QrMenuShopCard";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslation } from "react-i18next";

function QrCodePage() {
  const version = new Date().toISOString().split("T")[0];
  const { isMobile } = useMobile();
  const currentURL = window.location.href;
  const baseURL = currentURL.split("/").slice(0, 3).join("/");
  const [isAddMenuOpen, setIsAddMenuOpen] = useState(false);
  const { user } = useAuthStore();
  const [merchantData, setMerchantData] = useState();
  const [bMerchantData, setBMerchantData] = useState();
  const [bMerchantCategory, setBMerchantCategory] = useState([]);
  const [displayMenu, setDisplayMenu] = useState([]);
  const [isFetch, setIsFetch] = useState(false);
  const [cartList, setCartList] = React.useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [uuidProps, setUuidProps] = useState("");
  const [isTableQrOpen, setIsTableQrOpen] = useState(false);
  const { merchant_id, bmerchant_id, token, tableNumber, transaction_uuid } =
    useParams();
  const [showScrollTop, setShowScrollTop] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/table-merchant/${token}/${merchant_id}/${bmerchant_id}/${tableNumber}/${transaction_uuid}/menu`,
      title: "QrCodePage",
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 300) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const cartDataString = localStorage.getItem(`qr_merchant_${bmerchant_id}`);
    let cartData = cartDataString ? JSON.parse(cartDataString) : {};
    if (cartData.cartList) {
      setTotalQuantity(cartData.totalQuantity);
      setTotalPrice(cartData.totalPrice);
      setCartList(cartData.cartList);
    }
  }, [bmerchant_id]);

  useEffect(() => {
    if (!bMerchantData) return;
    const data = {
      uuidProps: uuidProps,
      totalPrice: totalPrice,
      totalQuantity: totalQuantity,
      cartList: cartList,
      merchant_name: bMerchantData.name,
      merchant_uuid: bMerchantData.uuid,
      customer_uuid: user.uuid,
    };

    if (cartList.length > 0) {
      localStorage.setItem(`qr_merchant_${bmerchant_id}`, JSON.stringify(data));
    }
    if (cartList.length === 0) {
      localStorage.removeItem(`qr_merchant_${bmerchant_id}`);
    }
  }, [
    bMerchantData,
    cartList,
    bmerchant_id,
    totalPrice,
    totalQuantity,
    user.uuid,
    uuidProps,
  ]);

  const calculateTotals = useCallback(() => {
    let totalQuantity = 0;
    let totalPrice = 0;

    cartList.forEach((item) => {
      const quantity = parseInt(item.quantity, 10) || 0;
      const price = parseInt(item.price, 10) || 0;

      totalQuantity += quantity;
      totalPrice += price;
    });

    setTotalQuantity(totalQuantity);
    setTotalPrice(totalPrice);
  }, [cartList]);

  useEffect(() => {
    calculateTotals();
  }, [calculateTotals]);

  const navigator = useNavigate();

  const fetchMerchant = useCallback(() => {
    getMerchantById(merchant_id)
      .then((res1) => {
        setMerchantData(res1.data);
        getBMerchantById(bmerchant_id)
          .then((res2) => {
            setBMerchantData(res2.data);
            getMenuByBMerchantId(res2.data.uuid)
              .then((res3) => {
                getCategoryByBMerchantId(res2.data.uuid)
                  .then((res4) => {
                    setBMerchantCategory(res4.data);
                    orderCategory(res3.data, res4.data);
                    setIsFetch(true);
                  })
                  .catch((error) => {
                    console.log(error, bmerchant_id);
                  });
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [bmerchant_id, merchant_id]);

  const orderCategory = (menus, categories) => {
    let menuTemp = [];
    categories.forEach((category) => {
      let filteredMenu = menus.filter((menu) => {
        return menu.categories_id.includes(category.id);
      });
      menuTemp.push({ category: category, menu: filteredMenu });
    });
    setDisplayMenu(menuTemp);
  };

  useEffect(() => {
    if (!isFetch) {
      fetchMerchant();
    }
  }, [fetchMerchant, isFetch]);

  const scrollCategoryRefs = displayMenu.map(() => createRef());

  const scrollToCategory = (index) => {
    if (scrollCategoryRefs[index] && scrollCategoryRefs[index].current) {
      const yOffset = isMobile ? -130 : -120;
      const element = scrollCategoryRefs[index].current;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (bMerchantData) {
      setUuidProps(bMerchantData.uuid);
    }
  }, [bMerchantData]);

  const handleQuantityChange = (id, delta) => {
    const updatedCartList = [...cartList];
    const itemToUpdateIndex = updatedCartList.findIndex(
      (itm) => itm.menu.id === id
    );

    if (itemToUpdateIndex !== -1) {
      const itemToUpdate = updatedCartList[itemToUpdateIndex];
      const newQuantity = (itemToUpdate.quantity || 0) + delta;

      if (newQuantity === 0) {
        updatedCartList.splice(itemToUpdateIndex, 1);
      } else if (newQuantity > 0) {
        updatedCartList[itemToUpdateIndex].quantity = newQuantity;
      }

      setCartList(updatedCartList);
    }
  };

  const handleGoToCartPage = () => {
    navigator(
      `/table-merchant/${token}/${merchant_id}/${bmerchant_id}/${tableNumber}/${transaction_uuid}/cartorder`
    );
  };

  const handleCopyClick = () => {
    const textArea = document.createElement("textarea");
    textArea.value = `${baseURL}/table-merchant/${token}/${merchant_id}/${bmerchant_id}/${tableNumber}/${transaction_uuid}`;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
      alert("URL copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy URL: ", err);
      alert("Failed to copy URL. Please try again.");
    } finally {
      document.body.removeChild(textArea);
    }
  };

  if (!bmerchant_id || bmerchant_id === "undefined") {
    return <Navigate to="/food" />;
  }

  return (
    <>
      <Helmet>
        <title>{merchantData?.name ?? "Loading..."} | POMPKINS Food</title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link rel="canonical" href={`${window.location.href}`} />
        <meta property="og:url" content={`${window.location.href}`} />
        <meta
          property="og:title"
          content={`${merchantData?.name ?? "Loading..."} | POMPKINS Food`}
        />
        <meta
          property="og:image"
          content={`${merchantData?.logo}?v=${version}` ?? DefaultProfile}
        />
        <meta
          name="twitter:image"
          content={`${merchantData?.logo}?v=${version}` ?? DefaultProfile}
        />
      </Helmet>
      {!isFetch && <LoadingScreen />}
      {isFetch && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <ModalQrCode setOpen={setIsTableQrOpen} open={isTableQrOpen} />
          <Box>
            <Box
              sx={{
                position: "relative",
                backgroundColor: "white",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundImage: `url(${
                    merchantData.background ?? DefaultBackground
                  })`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "150px",
                  alignItems: "end",
                  paddingX: 4,
                }}
              />
              <Box
                sx={{
                  pt: 2,
                  pl: 2,
                  bottom: 0,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box
                  alt="logo"
                  title="logo"
                  loading="lazy"
                  component="img"
                  src={merchantData.logo ?? DefaultProfile}
                  sx={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "20px",
                    objectFit: merchantData.logo ? "cover" : "contain",
                  }}
                />
                <Box
                  sx={{
                    ml: 2,
                    py: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 0.7,
                    backgroundColor: "white",
                    overflow: "hidden",
                  }}
                >
                  <Typography
                    className="font-bold"
                    sx={{
                      fontSize: "18px",
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                    }}
                  >
                    {merchantData.name}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: 0.7,
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => setIsTableQrOpen(!isTableQrOpen)}
                    >
                      Table QR
                    </Button>
                    <IconButton onClick={handleCopyClick}>
                      <ContentCopy sx={{ color: "black" }} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>
            {bMerchantCategory && (
              <MenuShopCategoryTab
                // category={bMerchantCategory}
                scrollToCategory={scrollToCategory}
                displayMenu={displayMenu}
                cartList={cartList}
                setCartList={setCartList}
                handleQuantityChange={handleQuantityChange}
                isQr={true}
              />
            )}
            {bMerchantCategory &&
              displayMenu
                .filter((item) => item.menu.length !== 0)
                .sort((a, b) => a.category.priority - b.category.priority)
                .map((object, index) => (
                  <Box key={index} sx={{ px: 2 }}>
                    <Typography
                      variant="h6"
                      sx={{
                        mt: 2,
                        mb: 2,
                        fontWeight: "bold",
                      }}
                      ref={scrollCategoryRefs[index]}
                    >
                      {object.category.name}
                    </Typography>
                    <Box>
                      {object.menu.map((menus, i) => (
                        <Box key={menus.id}>
                          <QrMenuShopCard
                            items={menus}
                            cartList={cartList}
                            setCartList={setCartList}
                            onIncrement={() =>
                              handleQuantityChange(menus.id, 1)
                            }
                            onDecrement={() =>
                              handleQuantityChange(menus.id, -1)
                            }
                          />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ))}
          </Box>
        </Box>
      )}
      {totalQuantity !== 0 && (
        <Box
          sx={{
            position: "sticky",
            backgroundColor: "white",
            boxShadow: 0,
            bottom: "6%",
            zIndex: 1000,
            width: "100%",
            maxWidth: "440px",
          }}
        >
          <Toolbar
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              py: 2,
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                setIsAddMenuOpen(!isAddMenuOpen);
                handleGoToCartPage();
              }}
              sx={{
                borderRadius: "2rem",
                fontSize: "16px",
                fontWeight: 500,
                px: 4,
                boxShadow: "0 3px 12px 0 rgba(255,161,9,.589)",
                ":hover": {
                  boxShadow: 0,
                },
              }}
            >
              {t("qrtable.allOrders")} {totalQuantity} {t("qrtable.order")}
            </Button>
          </Toolbar>
        </Box>
      )}
      {showScrollTop && (
        <Fab
          size="medium"
          variant="extended"
          onClick={scrollToTop}
          sx={{
            position: "fixed",
            bottom: cartList.length > 0 ? "20%" : "10%",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
          }}
        >
          {t("qrtable.backToTop")}
          <KeyboardArrowUpIcon />
        </Fab>
      )}
    </>
  );
}

export default QrCodePage;
